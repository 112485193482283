/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
// import cmsUtils from '../../cms/utils/cmsUtils';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export function TabPanel(props) {
  const item = props.item;
  const subItems = item.items || [];

  const tabLable1 = subItems[0].payloads[0].value;
  const itemOneLine1Tab1 = subItems[0].payloads[1].value;
  const itemOneLine2Tab1 = subItems[0].payloads[2].value;
  const itemOneLine3Tab1 = subItems[0].payloads[3].value;
  const ItemOneLinkTab1 = subItems[0].payloads[4].value;
  const itemTwoLine1Tab1 = subItems[0].payloads[5].value;
  const itemTwoLine2Tab1 = subItems[0].payloads[6].value;
  const itemTwoLine3Tab1 = subItems[0].payloads[7].value;
  const itemTwoLinkTab1 = subItems[0].payloads[8].value;
  const itemThreeLine1Tab1 = subItems[0].payloads[9].value;
  const itemThreeLine2Tab1 = subItems[0].payloads[10].value;
  const itemThreeLine3Tab1 = subItems[0].payloads[11].value;
  const itemThreeLinkTab1 = subItems[0].payloads[12].value;

  const tabLable2 = subItems[1].payloads[0].value;
  const itemOneLine1Tab2 = subItems[1].payloads[1].value;
  const itemOneLine2Tab2 = subItems[1].payloads[2].value;
  const itemOneLine3Tab2 = subItems[1].payloads[3].value;
  const ItemOneLinkTab2 = subItems[1].payloads[4].value;
  const itemTwoLine1Tab2 = subItems[1].payloads[5].value;
  const itemTwoLine2Tab2 = subItems[1].payloads[6].value;
  const itemTwoLine3Tab2 = subItems[1].payloads[7].value;
  const itemTwoLinkTab2 = subItems[1].payloads[8].value;
  const itemThreeLine1Tab2 = subItems[1].payloads[9].value;
  const itemThreeLine2Tab2 = subItems[1].payloads[10].value;
  const itemThreeLine3Tab2 = subItems[1].payloads[11].value;
  const itemThreeLinkTab2 = subItems[1].payloads[12].value;

  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    'cms_item',
    'tabPanel',
    item.cssClass || item.anchorName || ''
  );
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      css={style.tabPanel}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label={tabLable1} {...a11yProps(0)} />
            <Tab label={tabLable2} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
          itemOneLine1={itemOneLine1Tab1}
          itemOneLine2={itemOneLine2Tab1}
          itemOneLine3={itemOneLine3Tab1}
          ItemOneLink={ItemOneLinkTab1}
          itemTwoLine1={itemTwoLine1Tab1}
          itemTwoLine2={itemTwoLine2Tab1}
          itemTwoLine3={itemTwoLine3Tab1}
          itemTwoLink={itemTwoLinkTab1}
          itemThreeLine1={itemThreeLine1Tab1}
          itemThreeLine2={itemThreeLine2Tab1}
          itemThreeLine3={itemThreeLine3Tab1}
          itemThreeLink={itemThreeLinkTab1}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          itemOneLine1={itemOneLine1Tab2}
          itemOneLine2={itemOneLine2Tab2}
          itemOneLine3={itemOneLine3Tab2}
          ItemOneLink={ItemOneLinkTab2}
          itemTwoLine1={itemTwoLine1Tab2}
          itemTwoLine2={itemTwoLine2Tab2}
          itemTwoLine3={itemTwoLine3Tab2}
          itemTwoLink={itemTwoLinkTab2}
          itemThreeLine1={itemThreeLine1Tab2}
          itemThreeLine2={itemThreeLine2Tab2}
          itemThreeLine3={itemThreeLine3Tab2}
          itemThreeLink={itemThreeLinkTab2}
        >
          Item Two
        </CustomTabPanel>
      </Box>
    </div>
  );
}

function CustomTabPanel(props) {
  const {
    children,
    value,
    index,
    itemOneLine1,
    itemOneLine2,
    itemOneLine3,
    ItemOneLink,
    itemTwoLine1,
    itemTwoLine2,
    itemTwoLine3,
    itemTwoLink,
    itemThreeLine1,
    itemThreeLine2,
    itemThreeLine3,
    itemThreeLink,
    ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div className="prices">
            <div className="price">
              <div className="amount">${itemOneLine1}</div>
              <div className="price-text">{itemOneLine2}</div>
              <div className="features">{itemOneLine3}</div>
              <a
                className="button price-button"
                href={ItemOneLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pay Now
              </a>
            </div>
            <div className="price">
              <div className="amount">${itemTwoLine1}</div>
              <div className="price-text">{itemTwoLine2}</div>
              <div className="features">{itemTwoLine3}</div>
              <a
                className="button price-button"
                href={itemTwoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pay Now
              </a>
            </div>
            <div className="price">
              <div className="amount">${itemThreeLine1}</div>
              <div className="price-text">{itemThreeLine2}</div>
              <div className="features">{itemThreeLine3}</div>
              <a
                className="button price-button"
                href={itemThreeLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pay Now
              </a>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
