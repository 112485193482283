/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import utils from "../../utils";
// import cmsUtils from '../../cms/utils/cmsUtils';
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SiteLink } from "../index";

export function TabPanel2(props) {
  const item = props.item;
  const subItems = item.items || [];
  const furSubItems =
    subItems.map((obj) => obj.items.map((item) => item.payloads)) || [];

  const tabLable1 = subItems[0].payloads[0].value;
  const tabLable1Image = subItems[0].payloads[1].value;
  const tabLable1Link = subItems[0].payloads[2].value;
  const individual1Img = [];
  const individual1Link = [];
  const item1 = furSubItems[0].map((subArray) => {
    const imageUrl = subArray.map((obj) => obj.value);
    return imageUrl;
  });
  item1.forEach((subArray) => individual1Img.push(subArray[0]));
  item1.forEach((subArray) => individual1Link.push(subArray[1]));

  const tabLable2 = subItems[1].payloads[0].value;
  const tabLable2Image = subItems[1].payloads[1].value;
  const tabLable2Link = subItems[1].payloads[2].value;
  const individual2Img = [];
  const individual2Link = [];
  const item2 = furSubItems[1].map((subArray) => {
    const imageUrl = subArray.map((obj) => obj.value);
    return imageUrl;
  });
  item2.forEach((subArray) => individual2Img.push(subArray[0]));
  item2.forEach((subArray) => individual2Link.push(subArray[1]));

  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "tabPanel2",
    item.cssClass || item.anchorName || ""
  );
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      css={style.tabPanel}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            centered
          >
            <Tab label={tabLable1} {...a11yProps(0)} />
            <Tab label={tabLable2} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
          tabLableImage={tabLable1Image}
          tabLableLink={tabLable1Link}
          individualImg={individual1Img}
          individualLink={individual1Link}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          tabLableImage={tabLable2Image}
          tabLableLink={tabLable2Link}
          individualImg={individual2Img}
          individualLink={individual2Link}
        >
          Item Two
        </CustomTabPanel>
      </Box>
    </div>
  );
}

function CustomTabPanel(props) {
  const {
    children,
    value,
    index,
    tabLableImage,
    tabLableLink,
    individualImg,
    individualLink,
    ...other
  } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      className={value !== index ? "tabpanel-hidden" : "tabpanel-show"}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='prices-box'>
          <div className='prices-image'>
            <div className='price-image'>
              {/* <a href={tabLableLink} target='_blank' rel='noopener noreferrer'>
                <img src={tabLableImage} alt='paul dare course' />
              </a> */}
              <SiteLink to={tabLableLink}>
                <img src={tabLableImage} alt='paul dare course' />
              </SiteLink>
            </div>
            <div className='individual-image'>
              <p
                style={{
                  color: "rgb(255, 255, 255)",
                  fontSize: "1.5rem",
                  marginBottom: "2rem",
                }}
              >
                <h1>... or purchase individual modules</h1>
              </p>
              <hr style={{ borderTop: "2px solid rgb(255, 255, 255)" }} />
              <div className='individual-image-container'>
                {individualImg.map(function (item, i) {
                  return (
                    <div key={i} className='individual-image-item'>
                      <SiteLink to={individualLink[i]}>
                        <img src={item} alt='individual' />
                      </SiteLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
