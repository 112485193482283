import React, { useState } from "react";
import utils from "../../../utils";
import * as yt from "../../../lib/youtube/iframeApi";

export function VideoModulePanel2(props) {
  const item = props.item;
  const subItems = item.items || [];
  const video_link_array = [];
  subItems.map((i, index) => {
    video_link_array.push({
      id: index,
      value: yt.getYoutubeCode(i.payloads[0].value),
    });
    return null;
  });

  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "video-module-panel2",
    item.cssClass || item.anchorName || ""
  );

  const [videoIndex, setVideoIndex] = useState(0);
  const preVideos = () => {
    setVideoIndex((videoIndex - 1) % video_link_array.length);
  };
  const nextVideos = () => {
    setVideoIndex((videoIndex + 1) % video_link_array.length);
  };

  return (
    <>
      <section
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='v_container'>
            <div className='single_module_video'>
              <iframe
                width='800'
                height='450'
                title='YouTube video player'
                src={`https://www.youtube.com/embed/${video_link_array[videoIndex].value}?rel=0`}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </div>
            <div className='single_module_video_btns'>
              <div className='button-pre' onClick={preVideos}>
                Back
              </div>
              <div className='video_number'>
                Module {videoIndex + 1} of {video_link_array.length}
              </div>
              <div className='button-next' onClick={nextVideos}>
                Next
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
