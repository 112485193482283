import { mq } from 'cssInJs';

export default {
  container: mq({
    //border: '1px solid red',
    maxWidth: ['1700px'],
    '.row': {
      marginRight: '0px',
      marginLeft: '0px',
    },
    '.col__item': {
      paddingRight: '0',
      paddingLeft: '0',
    },
    '.col__item.change__flex__order': mq({
      order: ['2', null, 'unset'],
    }),
    '.bannerSize--standard, .bannerSize': mq({
      height: ['100%'],
    }),
  }),
  html__wrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  html__container: {
    //backgroundColor: variables.lightGray,
    //height: '100%',
    //flex: '1 0 auto',
    //position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '4rem',
    paddingRight: '2rem',
    justifyContent: 'center',

    '&.top': {},
    '&.middle': {
      justifyContent: 'center',
    },
    '&.bottom': {},
    '&.left': {
      alignItems: 'flex-start',
    },
    '&.centre': {
      alignItems: 'center',
    },
    '&.right': {
      alignItems: 'flex-end',
    },

    '&.check_other_panel_banner': {
      height: '90%',
    },
  },
  html__item: {
    //position: 'absolute',
    width: '100%',
    margin: 'unset',
    '&.width__short': {
      maxWidth: '250px',
    },
    '&.width__middle': {
      maxWidth: '400px',
    },
    '&.width__wild': {
      maxWidth: '100%',
    },
  },
};
