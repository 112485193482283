import { css } from '@emotion/core';
//import { mq } from 'cssInJs'

export default {
  sliderAwardPanel: css({
    marginTop: '50px',
    backgroundColor: 'transparent',
    '& .slick-prev:before, .slick-next:before': {
      color: 'black',
    },
    '& .slick-next': {
      right: '-2%',
    },
    '& .slick-prev': {
      left: '-2%',
    },
  }),
  sliderImageWrapper: {
    height: '100%',
  },
  sliderImageWrapper2: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    flexDirection: 'column',
    margin: 'auto',
    maxWidth: '500px',
  },
  sliderImage: css({
    width: '70%',
    margin: 'auto',
  }),
};
