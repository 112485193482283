import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { YoutubeNew } from '../youtubeNew/view';
import { Html } from '../html/view';

export function VideoPanelWithText(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);
  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl', null);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }
  const canPlayVideo = () => utils.css.screenWidth() >= 0
  const cssClass = utils.classNames('cms_item', 'youtubePanelFullScreen', item.cssClass || item.anchorName || '');
  
  return (
    <section className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{position: 'relative',overflow:"hidden"}}>
      <div className="container">
        <div className="video_panel_container">
          <YoutubeNew video={video} canPlayVideo={canPlayVideo} posterImageUrl={utils.site.resourcePath(posterFrameUrl)} />
          <Html item={item} cmsOption={cmsOption} />
        </div>
      </div>
    </section>
  );
}