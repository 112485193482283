import React from "react";
import utils from "../../../utils";
import * as yt from "../../../lib/youtube/iframeApi";

export function YoutubeNew2(props) {
  const youtubeBannerRef = React.useRef(null);
  // eslint-disable-next-line
  const { heightSet, videoLinkOrId, playerId } = props.video;
  const videoId = yt.getYoutubeCode(videoLinkOrId);

  return (
    <React.Fragment>
      <div
        className={utils.classNames(
          "youtubePlayer",
          "youtubePlayerNew",
          "youtubePlayerNew2",
          `youtubePlayer--${heightSet}`
        )}
        ref={youtubeBannerRef}
      >
        <iframe
          className='youtubePlayer__iframe'
          id={playerId}
          width='640'
          height='390'
          title='Youtube video'
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        ></iframe>
      </div>
    </React.Fragment>
  );
}
