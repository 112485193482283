import React, { useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';

export function BottomDock(props) {
  const links = [
    {
      url: '/',
      title: 'HOME',
      icon: 'dare-chisel.png',
      iconHover: 'dare-chisel.png',
    },
    {
      url: '/cut-like-a-master',
      title: 'GET STARTED',
      icon: 'dare-chisel.png',
      iconHover: 'dare-chisel.png',
    },
    {
      url: '/the-dare-chisel',
      title: 'DARE CHISEL',
      icon: 'dare-chisel.png',
      iconHover: 'dare-chisel.png',
    },
    {
      url: '/media',
      title: 'MEDIA',
      icon: 'dare-chisel.png',
      iconHover: 'dare-chisel.png',
    },
    {
      url: '/about',
      title: 'ABOUT',
      icon: 'dare-chisel.png',
      iconHover: 'dare-chisel.png',
    },
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className="icon">
          <img
            className="normal"
            src={`/assets/navicons/${link.icon}`}
            alt={link.title}
          />
          <img
            className="selected"
            src={`/assets/navicons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  const bottomMenu = useRef(null);

  return (
    <>
      <nav className="bottomMenu" ref={bottomMenu}>
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
    </>
  );
}
