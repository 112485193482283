import React from 'react'
import cmsUtils from '../../utils/cmsUtils'
import utils from '../../../utils'
import { SiteLink } from '../../../components'

export function SingleBanner(props) {
  const item = props.item
  const cmsOption = props.cmsOption || {}
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard'
  const alignV = cmsUtils.payload(item, 'AlignV') || '50%'
  const cssClass = utils.classNames(
    'cms_item',
    'single-banner',
    'banner-item',
    `bannerSize--${heightSet}`,
    item.cssClass || item.anchorName || ''
  )
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'))
  const captionTitle =
    cmsUtils.payload(item, 'CaptionTitle2') ||
    cmsUtils.payload(item, 'CaptionTitle')
  const caption = cmsUtils.payload(item, 'Caption')
  const linkUrl =
    cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl')
  const captionButton = cmsUtils.payload(item, 'CaptionButton')
  const captionButtonLinkUrl = cmsUtils.payload(item, 'CaptionButtonLinkUrl')

  const titlePanel = captionTitle ? (
    linkUrl ? (
      caption ? (
        <SiteLink to={linkUrl} className="banner-item__link">
          <div
            className="banner-item__title"
            dangerouslySetInnerHTML={{ __html: captionTitle }}
          ></div>
          <div
            className="banner-item__caption"
            dangerouslySetInnerHTML={{ __html: caption }}
          ></div>
          {captionButton && captionButtonLinkUrl && (
            <a class="button" href={captionButtonLinkUrl}>
              {captionButton}
            </a>
          )}
        </SiteLink>
      ) : (
        <SiteLink to={linkUrl} className="banner-item__link">
          <div
            className="banner-item__title"
            dangerouslySetInnerHTML={{ __html: captionTitle }}
          ></div>
          {captionButton && captionButtonLinkUrl && (
            <a class="button" href={captionButtonLinkUrl}>
              {captionButton}
            </a>
          )}
        </SiteLink>
      )
    ) : caption ? (
      <>
        <div
          className="banner-item__title"
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
        <div
          className="banner-item__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        ></div>
        {captionButton && captionButtonLinkUrl && (
          <a className="button" href={captionButtonLinkUrl}>
            {captionButton}
          </a>
        )}
      </>
    ) : (
      <>
        <div
          className="banner-item__title"
          dangerouslySetInnerHTML={{ __html: captionTitle }}
        ></div>
        {captionButton && captionButtonLinkUrl && (
          <a className="button" href={captionButtonLinkUrl}>
            {captionButton}
          </a>
        )}
      </>
    )
  ) : null

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      style={{
        backgroundImage: utils.css.bgUrlStyle(bgUrl),
        backgroundPositionY: alignV,
      }}
    >
      <div className="singleBanner_mask"></div>
      {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
    </div>
  )
}
