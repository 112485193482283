import React, { useState } from 'react'
import utils from '../../../utils'
import * as yt from '../../../lib/youtube/iframeApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

export function VideoModuleMobPanel(props) {
  const item = props.item
  const subItems = item.items || []
  const video_link_array = []
  const video_image_array = []
  const content_array = []
  subItems.map((i, index) => {
    video_link_array.push({
      id: index,
      value: yt.getYoutubeCode(i.payloads[0].value),
    })
    video_image_array.push({
      id: index,
      value: 'https://pauldare.stg5.jaba.com.au' + i.payloads[1].value,
    })
    content_array.push({
      id: index,
      value: yt.getYoutubeCode(i.payloads[0].value),
      title: i.payloads[2].value,
      des: i.payloads[3].value,
    })
    return null
  })

  const [toggleState, setToggleState] = useState(0)
  // eslint-disable-next-line
  const [slideImg, setSlideImg] = useState(video_link_array[0])
  const [selected, setSelected] = useState(null)

  const toggleTab = (index) => {
    setToggleState(index)
    const slide = video_link_array[index]
    setSlideImg(slide)
    toggle(index)
  }
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }
  const cmsOption = props.cmsOption || {}
  const cssClass = utils.classNames(
    'cms_item',
    'video-module-mobile-panel',
    item.cssClass || item.anchorName || ''
  )

  return (
    <>
      <section
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className="container">
          <div className="module_container">
            <div className="bloc-tabs-mob">
              {content_array.map((content, index) => (
                <div className="item">
                  <div
                    className={
                      toggleState === index ? 'tabs active-tabs' : 'tabs'
                    }
                    onClick={() => toggleTab(index)}
                  >
                    {content.title}
                    {selected === index ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </div>
                  <div
                    className={
                      selected === index ? 'tabs-content show' : 'tabs-content'
                    }
                  >
                    <div className="single_lesson_mobile">
                      <iframe
                        width="760"
                        height="450"
                        title="YouTube video player"
                        src={`https://www.youtube.com/embed/${content.value}?rel=0`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                    {content.des}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
