/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style'
import Slider from 'react-slick'
import utils from '../../../utils'
import cmsUtils from '../../../cms/utils/cmsUtils'

export function RotatingReviewPanel(props) {
  const item = props.item
  const subItems = item.items || []
  const cmsOption = props.cmsOption || {}
  const cssClass = utils.classNames(
    'cms_item',
    'rotatingReviewItems',
    item.cssClass || item.anchorName || ''
  )

  const RotatingReviewItems = subItems.map((subItem, index) => {
    const reviewContent = cmsUtils.payload(subItem, 'ReviewContent')
    const reviewer = cmsUtils.payload(subItem, 'Reviewer')
    const starsImage = cmsUtils.payload(subItem, 'StarsImage')
    const textColor = cmsUtils.payload(subItem, 'TextColor')
    return (
      <div key={index} css={style.sliderImageWrapper}>
        <div css={style.sliderImageWrapper2}>
          <img
            css={style.starsImage}
            key={subItem.itemId}
            alt={'sliderimage-' + index}
            src={starsImage}
            className="starsImage"
          />
          <p css={style.reviewContent} style={{ color: textColor }}>
            {reviewContent}
          </p>
          <h5 css={style.reviewer} style={{ color: textColor }}>
            {reviewer}
          </h5>
        </div>
      </div>
    )
  })

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div
      css={style.rotatingReviewPanel}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <Slider {...settings}>{RotatingReviewItems}</Slider>
    </div>
  )
}
